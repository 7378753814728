
import {
  IonContent,
  IonPage,
  IonLabel,
  IonButton,
  IonItem,
  IonProgressBar,
  IonRange,
  IonBackdrop,
  IonSelect,
  IonSelectOption,
  IonList,
  IonListHeader,
} from "@ionic/vue";
import { reactive, computed, ref } from "vue";
import jb from "@/assets/Job.json";
import sk from "@/assets/Skill.json";
import at from "@/assets/Attribute.json";
import ks from "@/assets/KokoroStatus.json";
import km from "@/assets/KokoroMaxByLevel.json";
import MyWorker from "worker-loader?filename=dist/[name].js!../workers/dqw.worker";
import {
  Kokoro,
  SearchResult,
  SearchResultRow,
  WorkerPostData,
} from "@/typings/dqw";

export default {
  name: "Tab1",
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonButton,
    IonProgressBar,
    IonRange,
    IonBackdrop,
    IonSelect,
    IonSelectOption,
    IonList,
    IonListHeader,
  },
  setup() {
    const state = reactive({
      result: {
        maxval: 0,
        rows: [] as SearchResultRow[],
      } as SearchResult,
      progValue: 0,
      isAttrDisabled: false,
    });
    const bProcessing = computed(() => state.progValue > 0);

    // KokoroStatusから配列作成
    const ksAry: Kokoro[] = ks.map((value) => {
      const at: number[][] = [
        // メラ ギラ イオ ヒャド バギ デイン ドルマ ジバリア
        [0, 0, 0, 0, 0, 0, 0, 0], // 斬撃
        [0, 0, 0, 0, 0, 0, 0, 0], // 体技
        [0, 0, 0, 0, 0, 0, 0, 0], // じゅもん
        [0, 0, 0, 0, 0, 0, 0, 0], // 回復
      ];

      // "スキルの斬撃ダメージ": 10,
      // "スキルの体技ダメージ": 5,
      let costplus = 0;
      Object.entries(value.skill).map(([key, val]) => {
        const dx = Number(val);
        switch (key) {
          case "こころ最大コスト":
            costplus = dx || 0;
            break;
          case "スキルの斬撃・体技ダメージ":
            at[0] = at[0].map((i) => i + dx);
            at[1] = at[1].map((i) => i + dx);
            break;
          case "スキルの斬撃ダメージ":
            at[0] = at[0].map((i) => i + dx);
            break;
          case "スキルの体技ダメージ":
            at[1] = at[1].map((i) => i + dx);
            break;
          case "じゅもんダメージ":
            at[2] = at[2].map((i) => i + dx);
            break;
          case "スキルHP回復効果":
            at[3] = at[3].map((i) => i + dx);
            break;
          default: {
            const result = key.match(/(.+)属性(.*)ダメージ/);
            if (result != null) {
              const indexRow = [
                "メラ",
                "ギラ",
                "イオ",
                "ヒャド",
                "バギ",
                "デイン",
                "ドルマ",
                "ジバリア",
              ].findIndex((s) => s === result[1]);

              if (result[2] === "とくぎ") {
                at[0][indexRow] += dx;
                at[1][indexRow] += dx;
              } else if (result[2] === "じゅもん") {
                at[2][indexRow] += dx;
              } else if (result[2] === "") {
                at[0][indexRow] += dx;
                at[1][indexRow] += dx;
                at[2][indexRow] += dx;
              } else {
                console.log("Error :", key);
              }
            }
            break;
          }
        }
      });

      return {
        no: value.no,
        name: value.name,
        icon: value.icon,
        color: value.color,
        cost: value.cost,
        costplus: costplus,
        hp: value.hp,
        mp: value.mp,
        atk: value.atk,
        def: value.def,
        atkm: value.atkm,
        recm: value.recm,
        spd: value.spd,
        dex: value.dex,
        skill: at,
        skillStr: value.skillStr,
      } as Kokoro;
    });

    const selectedJob = ref("バトルマスター");
    const selectedSkill = ref("斬撃");
    const selectedAttr = ref("指定なし");
    const selectedLevel = ref(40);

    const kokoroCostMax = computed(() => {
      return km.senior[selectedLevel.value - 1];
    });
    const isAttrDisabled = computed(() => selectedSkill.value == "回復");

    const sortBy = (key: string) => {
      console.log("sortBy", key);
      state.result.rows.sort((a, b) => {
        return b[key] - a[key];
      });
      return;
    };

    const onWorkerMessage = (event: { data: number }) => {
      if (event.data["status"] == "progress") {
        state.progValue = event.data["value"] / event.data["max"];
      } else if (event.data["status"] == "finish") {
        state.progValue = 0;
        state.result = event.data["searchResult"];
        console.log(state.result);
      } else {
        console.error("onWorkerMessage", event.data);
      }
    };

    const w = new MyWorker();
    const doCalc = () => {
      console.log(
        "doCalc Start!",
        selectedJob.value,
        selectedLevel.value,
        selectedSkill.value,
        selectedAttr.value
      );

      const selectedIcon = localStorage.selectedIcon || [];
      const pd: WorkerPostData = {
        limitCost: km.senior[selectedLevel.value - 1],
        slotColors: jb.find((v) => v.name === selectedJob.value)?.slot || [
          "",
          "",
          "",
          "",
        ],
        kokoros: ksAry.filter((v) => selectedIcon.indexOf(v.icon) > 0),
        r: sk.findIndex((v) => v.name == selectedSkill.value),
        c: at.findIndex((v) => v.name == selectedAttr.value) - 1,
      };

      w.onmessage = onWorkerMessage; // workerからの処理結果をキャッチするリスナーを登録
      w.postMessage(pd); // イベント経由でworkerに処理を依頼
      console.log("doCalc End!");
    };

    return {
      jb,
      sk,
      at,
      km,
      selectedJob,
      selectedLevel,
      selectedSkill,
      selectedAttr,
      kokoroCostMax,
      isAttrDisabled,
      doCalc,
      state,
      sortBy,
      bProcessing,
    };
  },
};
