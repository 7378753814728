<template>
  <ion-page>
    <ion-content>
      <ion-list lines="full">
        <ion-list-header>
          <ion-label color="primary">最強こころパターン検索ツール</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label position="stacked">職業</ion-label>
          <ion-select v-model="selectedJob" interface="popover">
            <ion-select-option
              v-for="job in jb"
              v-bind:key="job.name"
              :value="job.name"
              layout="icon-start"
              >{{ job.name }}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="stacked"
            >レベル {{ selectedLevel }} 【こころ最大コスト
            {{ km.senior[selectedLevel - 1] }}】</ion-label
          >
          <ion-range
            v-model="selectedLevel"
            min="30"
            max="65"
            step="1"
            snaps
          ></ion-range>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">スキル</ion-label>
          <ion-select v-model="selectedSkill">
            <ion-select-option
              v-for="skill in sk"
              v-bind:key="skill.name"
              :value="skill.name"
              layout="icon-start"
            >
              {{ skill.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="floating">属性</ion-label>
          <ion-select
            v-model="selectedAttr"
            :disabled="isAttrDisabled"
            interface="popover"
          >
            <ion-select-option
              v-for="attr in at"
              v-bind:key="attr.name"
              :value="attr.name"
              layout="icon-start"
            >
              {{ attr.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-button :disabled="bProcessing" @click="doCalc" expand="block"
          >検索</ion-button
        >
      </ion-list>

      <!-- <div style="margin: 0 auto; width: 1080px; border: solid 1px #ff773e"> -->
      <!-- <ion-grid>
        <ion-row>
          <ion-col size="2"><legend>職業</legend></ion-col>
          <ion-col>
            <ion-segment value="バトルマスター" v-model="selectedJob">
              <ion-segment-button
                v-for="job in jb"
                v-bind:key="job.name"
                :value="job.name"
                layout="icon-start"
              >
                {{ job.name }}
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="2">レベル</ion-col>
          <ion-col size="1">
            {{ selectedLevel }}
          </ion-col>

          <ion-col>
            <ion-range
              v-model="selectedLevel"
              min="30"
              max="65"
              step="1"
              snaps
            ></ion-range>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="2">こころ最大コスト</ion-col>
          <ion-col size="2">{{ kokoroCostMax }}</ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="2">スキル</ion-col>
          <ion-col>
            <ion-segment v-model="selectedSkill" @click="clickSkill">
              <ion-segment-button
                v-for="skill in sk"
                v-bind:key="skill.name"
                :value="skill.name"
                layout="icon-start"
              >
                {{ skill.name }}
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="2">属性</ion-col>
          <ion-col>
            <ion-segment
              :disabled="state.isAttrSegmentDisabled"
              v-model="selectedAttr"
            >
              <ion-segment-button
                v-for="attr in at"
                v-bind:key="attr.name"
                :value="attr.name"
                layout="icon-start"
              >
                {{ attr.name }}
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-align-self-center">
            <ion-button :disabled="bProcessing" @click="doCalc"
              >検索</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid> -->

      <template v-if="bProcessing">
        <ion-backdrop style="opacity: 0.2"></ion-backdrop>
        <ion-progress-bar :value="state.progValue" />
      </template>

      <template v-if="state.result.rows.length == 0 && state.progValue == 1">
        <div>てきせつなみつからない</div>
      </template>

      <template v-if="state.result.rows.length > 0">
        <div>最大値は、{{ state.result.maxval }} です</div>
        <div class="scroll">
          <table class="result">
            <tr>
              <th class="kokoroth">こころ１</th>
              <th class="kokoroth">こころ２</th>
              <th class="kokoroth">こころ３</th>
              <th class="kokoroth">こころ４</th>
              <th class="statusth" @click="sortBy('cost')">コ</th>
              <th class="statusth" @click="sortBy('hp')">HP</th>
              <th class="statusth" @click="sortBy('mp')">MP</th>
              <th class="statusth" @click="sortBy('atk')">力</th>
              <th class="statusth" @click="sortBy('def')">守り</th>
              <th class="statusth" @click="sortBy('atkm')">攻魔</th>
              <th class="statusth" @click="sortBy('recm')">回魔</th>
              <th class="statusth" @click="sortBy('spd')">素早</th>
              <th class="statusth" @click="sortBy('dex')">器用</th>
            </tr>
            <template v-for="(r, i) in state.result.rows" v-bind:key="i">
              <tr>
                <td rowspan="2" class="kokorotd">
                  <img
                    :src="'images/monstericon/' + r.kokoro[0].icon + '.webp'"
                    :title="r.kokoro[0].name"
                    width="64"
                  />
                </td>
                <td rowspan="2" class="kokorotd">
                  <img
                    :src="'images/monstericon/' + r.kokoro[1].icon + '.webp'"
                    :title="r.kokoro[1].name"
                    width="64"
                  />
                </td>
                <td rowspan="2" class="kokorotd">
                  <img
                    :src="'images/monstericon/' + r.kokoro[2].icon + '.webp'"
                    :title="r.kokoro[2].name"
                    width="64"
                  />
                </td>
                <td rowspan="2" class="kokorotd">
                  <img
                    :src="'images/monstericon/' + r.kokoro[3].icon + '.webp'"
                    :title="r.kokoro[3].name"
                    width="64"
                  />
                </td>
                <td class="statustd">{{ r.cost }}</td>
                <td class="statustd">{{ r.hp }}</td>
                <td class="statustd">{{ r.mp }}</td>
                <td class="statustd">{{ r.atk }}</td>
                <td class="statustd">{{ r.def }}</td>
                <td class="statustd">{{ r.atkm }}</td>
                <td class="statustd">{{ r.recm }}</td>
                <td class="statustd">{{ r.spd }}</td>
                <td class="statustd">{{ r.dex }}</td>
              </tr>
              <tr>
                <td colspan="9">
                  <details>
                    <summary>特殊効果</summary>
                    <span v-html="r.skillStr"></span>
                  </details>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </template>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.result {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.kokoroth {
  width: 66px;
  border: 1px solid #000066;
}
.statusth {
  width: 36px;
  border: 1px solid #000066;
}

.kokorotd {
  vertical-align: top;
  border: 1px solid #000066;
}

.statustd {
  height: 30px;
  text-align: right;
  vertical-align: center;
  border: 1px solid #000066;
}

.scroll {
  overflow: auto;
  white-space: nowrap;
}

.scroll::-webkit-scrollbar {
  height: 10px;
}

.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}
</style>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonLabel,
  IonButton,
  IonItem,
  IonProgressBar,
  IonRange,
  IonBackdrop,
  IonSelect,
  IonSelectOption,
  IonList,
  IonListHeader,
} from "@ionic/vue";
import { reactive, computed, ref } from "vue";
import jb from "@/assets/Job.json";
import sk from "@/assets/Skill.json";
import at from "@/assets/Attribute.json";
import ks from "@/assets/KokoroStatus.json";
import km from "@/assets/KokoroMaxByLevel.json";
import MyWorker from "worker-loader?filename=dist/[name].js!../workers/dqw.worker";
import {
  Kokoro,
  SearchResult,
  SearchResultRow,
  WorkerPostData,
} from "@/typings/dqw";

export default {
  name: "Tab1",
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonButton,
    IonProgressBar,
    IonRange,
    IonBackdrop,
    IonSelect,
    IonSelectOption,
    IonList,
    IonListHeader,
  },
  setup() {
    const state = reactive({
      result: {
        maxval: 0,
        rows: [] as SearchResultRow[],
      } as SearchResult,
      progValue: 0,
      isAttrDisabled: false,
    });
    const bProcessing = computed(() => state.progValue > 0);

    // KokoroStatusから配列作成
    const ksAry: Kokoro[] = ks.map((value) => {
      const at: number[][] = [
        // メラ ギラ イオ ヒャド バギ デイン ドルマ ジバリア
        [0, 0, 0, 0, 0, 0, 0, 0], // 斬撃
        [0, 0, 0, 0, 0, 0, 0, 0], // 体技
        [0, 0, 0, 0, 0, 0, 0, 0], // じゅもん
        [0, 0, 0, 0, 0, 0, 0, 0], // 回復
      ];

      // "スキルの斬撃ダメージ": 10,
      // "スキルの体技ダメージ": 5,
      let costplus = 0;
      Object.entries(value.skill).map(([key, val]) => {
        const dx = Number(val);
        switch (key) {
          case "こころ最大コスト":
            costplus = dx || 0;
            break;
          case "スキルの斬撃・体技ダメージ":
            at[0] = at[0].map((i) => i + dx);
            at[1] = at[1].map((i) => i + dx);
            break;
          case "スキルの斬撃ダメージ":
            at[0] = at[0].map((i) => i + dx);
            break;
          case "スキルの体技ダメージ":
            at[1] = at[1].map((i) => i + dx);
            break;
          case "じゅもんダメージ":
            at[2] = at[2].map((i) => i + dx);
            break;
          case "スキルHP回復効果":
            at[3] = at[3].map((i) => i + dx);
            break;
          default: {
            const result = key.match(/(.+)属性(.*)ダメージ/);
            if (result != null) {
              const indexRow = [
                "メラ",
                "ギラ",
                "イオ",
                "ヒャド",
                "バギ",
                "デイン",
                "ドルマ",
                "ジバリア",
              ].findIndex((s) => s === result[1]);

              if (result[2] === "とくぎ") {
                at[0][indexRow] += dx;
                at[1][indexRow] += dx;
              } else if (result[2] === "じゅもん") {
                at[2][indexRow] += dx;
              } else if (result[2] === "") {
                at[0][indexRow] += dx;
                at[1][indexRow] += dx;
                at[2][indexRow] += dx;
              } else {
                console.log("Error :", key);
              }
            }
            break;
          }
        }
      });

      return {
        no: value.no,
        name: value.name,
        icon: value.icon,
        color: value.color,
        cost: value.cost,
        costplus: costplus,
        hp: value.hp,
        mp: value.mp,
        atk: value.atk,
        def: value.def,
        atkm: value.atkm,
        recm: value.recm,
        spd: value.spd,
        dex: value.dex,
        skill: at,
        skillStr: value.skillStr,
      } as Kokoro;
    });

    const selectedJob = ref("バトルマスター");
    const selectedSkill = ref("斬撃");
    const selectedAttr = ref("指定なし");
    const selectedLevel = ref(40);

    const kokoroCostMax = computed(() => {
      return km.senior[selectedLevel.value - 1];
    });
    const isAttrDisabled = computed(() => selectedSkill.value == "回復");

    const sortBy = (key: string) => {
      console.log("sortBy", key);
      state.result.rows.sort((a, b) => {
        return b[key] - a[key];
      });
      return;
    };

    const onWorkerMessage = (event: { data: number }) => {
      if (event.data["status"] == "progress") {
        state.progValue = event.data["value"] / event.data["max"];
      } else if (event.data["status"] == "finish") {
        state.progValue = 0;
        state.result = event.data["searchResult"];
        console.log(state.result);
      } else {
        console.error("onWorkerMessage", event.data);
      }
    };

    const w = new MyWorker();
    const doCalc = () => {
      console.log(
        "doCalc Start!",
        selectedJob.value,
        selectedLevel.value,
        selectedSkill.value,
        selectedAttr.value
      );

      const selectedIcon = localStorage.selectedIcon || [];
      const pd: WorkerPostData = {
        limitCost: km.senior[selectedLevel.value - 1],
        slotColors: jb.find((v) => v.name === selectedJob.value)?.slot || [
          "",
          "",
          "",
          "",
        ],
        kokoros: ksAry.filter((v) => selectedIcon.indexOf(v.icon) > 0),
        r: sk.findIndex((v) => v.name == selectedSkill.value),
        c: at.findIndex((v) => v.name == selectedAttr.value) - 1,
      };

      w.onmessage = onWorkerMessage; // workerからの処理結果をキャッチするリスナーを登録
      w.postMessage(pd); // イベント経由でworkerに処理を依頼
      console.log("doCalc End!");
    };

    return {
      jb,
      sk,
      at,
      km,
      selectedJob,
      selectedLevel,
      selectedSkill,
      selectedAttr,
      kokoroCostMax,
      isAttrDisabled,
      doCalc,
      state,
      sortBy,
      bProcessing,
    };
  },
};
</script>